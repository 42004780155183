import { motion } from "framer-motion";
import { animate } from "framer-motion";
export default function WDzigzag({zzi1,zzh1,zzt1,zzi2,zzh2,zzt2,zzi3,zzh3,zzt3}) {
  return (
    <section className="overflow-x-hidden">
      <div className="bg-ninjaml mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className=" grid grid-cols-1  lg:grid-cols-2 ">
          <motion.div
            transition={{ duration: 1 }}
            whileInView={{ x: [-100, 0] }}
            className="hidden lg:block relative h-80 overflow-hidden sm:h-80 lg:h-full"
          >
            <img
              alt="Party"
              src={zzi1}
              className="absolute inset-0 h-full w-full object-cover"
            />
          </motion.div>

          <div className="lg:py-16 p-5">
            <article className="space-y-4 text-white">
              <h2 className="text-2xl font-bold sm:text-3xl">
                {zzh1}
              </h2>

              <p>
                {zzt1}
              </p>
              <p>Here's how we work our magic:</p>
              <p>•	<b>Know your audience:</b> We peel back the layers of your target demographic, understanding their desires, fears, and late-night Google searches. This intel drives laser-focused campaigns that resonate like a perfectly-timed beat drop.</p>
              <p>•	<b>Craft irresistible offers:</b> Free ebooks, exclusive webinars, discounts so juicy they'll need napkins -we create content that screams "Take me! Take me now!" Your audience won't be able to resist, and their info will be yours for the taking.</p>
              <p>•	<b>Channel your inner alchemist:</b> We blend paid advertising, social media wizardry, and SEO spells to ensure your lead magnet reaches the eyes it needs to. Think of it as casting a net so wide, even the most elusive leads can't escape.</p>
              <p>•	<b>Nurture, nurture, nurture:</b> Leads aren't just numbers, they're potential customers waiting to be wooed. We nurture them with personalized emails, targeted content, and a sprinkle of marketing magic, transforming them from leads into loyal fans.</p>
            </article>
          </div>
        </div>

        {/* <div className=" grid grid-cols-1  lg:grid-cols-2 ">
          <div className="lg:py-16 p-5">
            <article className="space-y-4 text-white">
              <h2 className="text-2xl font-bold sm:text-3xl">
                {zzh2}
              </h2>

              <p>
                {zzt2}
              </p>
            </article>
          </div>
          <motion.div
            transition={{ duration: 1 }}
            initial={{ x: 0 }}
            whileInView={{ x: [100, 0] }}
            className="hidden lg:block relative h-80 overflow-hidden sm:h-80 lg:h-full"
          >
            <img
              alt="Party"
              src={zzi2}
              className="absolute inset-0 h-full w-full object-cover"
            />
          </motion.div>
        </div> */}

        {/* <div className=" grid grid-cols-1  lg:grid-cols-2 ">
          <motion.div
            transition={{ duration: 1 }}
            whileInView={{ x: [-100, 0] }}
            className="hidden lg:block relative h-80 overflow-hidden sm:h-80 lg:h-full"
          >
            <img
              alt="Party"
              src={zzi3}
              className="absolute inset-0 h-full w-full object-cover"
            />
          </motion.div>

          <div className="lg:py-16 p-5">
            <article className="space-y-4 text-white">
              <h2 className="text-2xl font-bold sm:text-3xl">
                {zzh3}
              </h2>

              <p>
                {zzt3}
              </p>
            </article>
          </div>
        </div> */}

      </div>
    </section>
  );
}

import I1 from "../assets/1.png";
import I2 from "../assets/2.png";
import I3 from "../assets/3.png";
import { motion } from "framer-motion";
export default function HomeBanner({
  cImg1,
  cHead1,
  cText1,
  cImg2,
  cHead2,
  cText2,
  cImg3,
  cHead3,
  cText3,
}) {
  return (
    <>
    
      <div className="bg-white  overflow-hidden pb-10">
      
        <div class="mx-auto max-w-3xl text-center ">
          <h1 class=" bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
            <span class="sm:block text-white"> </span>
          </h1>
          {/* mx-auto mt-24 max-w-xl sm:text-xl/relaxed text-white md:px-10 */}
          <h1 class="mt-24  text-2xl text-ninjaml font-extrabold ">
          Crafting Success Stories Across 20+ Industries.
          </h1>
          <h2 class="mt-10  text-xl text-ninjaml ">
          360 Marketing | Web Development | Lead Generation | HR Excellence.
          </h2>
        </div>
        <div className="mt-16  grid gap-5 md:grid-cols-2 lg:grid-cols-3 content-center px-20   ">
          <motion.div
            transition={{ duration: 1 }}
            whileInView={{ x: [-100, 0] }}
          >
            <div className="rounded-2xl  p-1 shadow-xl border-2 border-dashed border-ninjaml hover:border-ninjasl ">
              <a
                href="digitalmarketing"
                className="block rounded-xl  p-4 sm:p-6 lg:p-8 "
              >
                <div className="">
                  <div className="App flex justify-center items-center">
                    <img src={I1} alt="icon " />
                  </div>
                  {/* <h3 className="text-sm font-bold text-white sm:text-xl text-center">
                  Unleash the power of your brand with our Social Media Management plan, starting at just £99 per month
                  </h3> */}
                  <p className="mt-2 text-sm text-ninjaml text-center font-bold">
                    {/* {cText1} */}
                    Attract high-quality leads with targeted campaigns. Watch your business blossom with an influx of new opportunities.
                  </p>
                  <p className="mt-2 text-sm text-ninjasd text-center font-bold">Read More ➤</p>
                </div>
              </a>
            </div>
          </motion.div>

          <motion.div transition={{ duration: 1 }} whileInView={{ x: [0, 0] }}>
            <div className="rounded-2xl p-1 shadow-xl border-2 border-dashed border-ninjaml hover:border-ninjasl ">
              <a href="webdev" className="block rounded-xl  p-4 sm:p-6 lg:p-8">
                <div className="">
                  <div className="App flex justify-center items-center">
                    <img src={I2} alt="React Logo" />
                  </div>
                  {/* <h3 className="text-sm font-bold text-white sm:text-xl text-center">
                  Elevate your online presence with a professionally designed website starting at only £589.
                  </h3> */}
                  <p className="mt-2 text-sm text-ninjaml text-center font-bold">
                    {/* {cText2} */}
                    Unleash online success with stunning design, flawless UI, and ongoing support. Be the star of the digital universe.
                  </p>
                  <p className="mt-2 text-sm text-ninjasd text-center font-bold">Read More ➤</p>
                </div>
              </a>
            </div>
          </motion.div>

          <motion.div
            transition={{ duration: 1 }}
            whileInView={{ x: [100, 0] }}
          >
            <div className="rounded-2xl  p-1 shadow-xl border-2 border-dashed border-ninjaml hover:border-ninjasl ">
              <a href="talent" className="block rounded-xl p-4 sm:p-6 lg:p-8">
                <div className="">
                  <div className="App flex justify-center items-center">
                    <img src={I3} alt="React Logo" />
                  </div>
                  {/* <h3 className="text-sm  text-white sm:text-xl text-center">
                  Empower your business with a full-time developer/marketing specialist at an unbeatable rate of £599 per month. Benefit from their expertise 5 days a week, 8 hours a day.
                  </h3> */}
                  <p className="mt-2 text-sm text-ninjaml text-center font-bold">
                    {/* {cText3} */}
                    Elevate with our 360 marketing strategies , from targeted ads to social media management, get proven results.
                  </p>
                  <p className="mt-2 text-sm text-ninjasd text-center font-bold">Read More ➤</p>
                </div>
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}

import LogoS1 from "../assets/logoslide1.png"
import LogoS2 from "../assets/logoslide2.png"
import LogoS3 from "../assets/logoslide3.png"
import LogoS4 from "../assets/logoslide4.png"
import LogoS5 from "../assets/logoslide5.png"
import LogoS6 from "../assets/logoslide6.png"
import LogoS7 from "../assets/logoslide7.png"
import LogoS8 from "../assets/logoslide8.png"
import LogoS9 from "../assets/logoslide9.png"
import LogoS10 from "../assets/logoslide10.png"

  const LOGOS = [
    <img src={LogoS1} alt="Logo 1" width={100} height={100}  />,
    <img src={LogoS2} alt="Logo 2" width={100} height={100}  />,
    <img src={LogoS3} alt="Logo 3" width={100} height={100}  />,
    <img src={LogoS4} alt="Logo 4" width={100} height={100} />,
    <img src={LogoS5} alt="Logo 5" width={100} height={100}  />,
    <img src={LogoS6} alt="Logo 6" width={100} height={100}  />,
    <img src={LogoS7} alt="Logo 7" width={100} height={100}  />,
    <img src={LogoS8} alt="Logo 8" width={100} height={100}  />,
    <img src={LogoS9} alt="Logo 9" width={100} height={100}  />,
    <img src={LogoS10} alt="Logo 10" width={100} height={100}  />,
  ];
  
  export const InfiniteSlider = () => {
    return (
      <div className="relative m-auto pb-10 overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
        <div className="animate-infinite-slider flex w-[calc(250px*10)]">
          {LOGOS.map((logo, index) => (
            <div
              className="slide flex w-[125px] items-center justify-center p-5"
              key={index}
            >
              {logo}
            </div>
          ))}
          {LOGOS.map((logo, index) => (
            <div
              className="slide flex w-[125px] items-center justify-center p-5"
              key={index}
            >
              {logo}
            </div>
          ))}
        </div>
      </div>
    );
  };
  
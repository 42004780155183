import { motion } from "framer-motion";
export default function Pricing() {
  return (
    <div>
      <section className="p-6  bg-ninjaml">
        <div className=" mx-auto container">
          <div className="flex flex-col lg:items-center justify-center w-full">
            <h1 className="font-extrabold text-white text-3xl md:text-4xl">
            Unlock the perfect strategy for your business
            </h1>
            <p className="mt-2.5 lg:w-1/2 text-white lg:text-center text-2xl">
            Explore our range of tailored plans to elevate your brand
            </p>
          </div>
          <div className="flex items-center justify-center w-full">
            <div className="pt-14">
              <div className="container mx-auto">
                <div className="flex flex-wrap mb-12 justify-between sm:justify-center -mx-6">
                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                    <motion.div
                      initial={{ opacity: 0, y: 100 }} // Initial state: invisible and shifted down
                      animate={{ opacity: 1, y: 0 }} // Animation state: fade in and move up
                      transition={{ duration: 1 }}
                    >
                      <div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                        <h4 className="text-2xl text-ninjaml font-semibold pb-8">
                          Basic
                        </h4>
                        <ul className="flex flex-col mb-6">
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className="mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Social Media Management: Benefit from 5x impactful social media posts per week, keeping your audience captivated.
                            </p>
                          </li>
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className="mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Content Writing: Our skilled content writing team will handle your website copy, blog posts, articles and social media copy. 
                            </p>
                          </li>
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className="mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Graphic Design: Our team of skilled graphic designers will create custom graphics that align perfectly with your brand.
                            </p>
                          </li>
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className=" mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Flexible commitment: Embrace our monthly rolling plan with no long-term contracts, granting you the freedom to adapt as needed.
                            </p>
                          </li>
                          
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className="opacity-0 mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-400 text-base font-normal">
                            
                            </p>
                          </li>
                        </ul>
                        <p className="text-base text-ninjaml relative pl-3">
                          <span className="font-light text-lg">£</span>
                          <span className="text-2xl font-semibold">199 + VAT</span>
                          <span className="text-gray-600 font-light text-lg">
                            /month
                          </span>
                        </p>
                        <a href="https://wa.me/+923360003866?text=👋%20I'm%20interested%20in%20the%20Axis%20Alpha%20Basic%20Social%20Media%20plan.">
                        <button className="mt-5 w-full bg-ninjaml hover:bg-ninjamd focus:outline-none transition duration-150 ease-in-out rounded text-white px-8 py-3 text-base font-semibold py-3">
                          Choose
                        </button></a>
                      </div>
                    </motion.div>
                  </div>

                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                    <motion.div
                      initial={{ opacity: 0, y: 100 }} // Initial state: invisible and shifted down
                      animate={{ opacity: 1, y: 0 }} // Animation state: fade in and move up
                      transition={{ duration: 2 }}
                    >
                      <div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                        <h4 className="text-2xl text-ninjaml font-semibold pb-8">
                          Standard
                        </h4>
                        <ul className="flex flex-col mb-6">
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className="mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Digital Marketing Strategy: Our senior marketing professionals will provide a comprehensive marketing strategy for your business. The plan will include competitor analysis, audience segmentation, SWOT analysis, current website and social media standing, email performance and optimization. 
                            </p>
                          </li>
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className="mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Email Marketing: Get access to email marketing professionals that will not only enhance the size of your database with lead gen campaigns but also ensure the right message is delivered to your existing database. We take pride in ranking above the industry benchmarks for email open and click rates.  
                            </p>
                          </li>
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className="mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Social Media Management: Benefit from 5x impactful social media posts per week, keeping your audience captivated on all the social media channels including FB, Insta, Twitter and LinkedIn.
                            </p>
                          </li>
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className=" mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Content Writing: Our skilled content writing team will handle your website copy, blog posts, articles and social media copy. 
                            </p>
                          </li>
                          
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className=" mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Graphic Design: Our team of skilled graphic designers will create custom graphics that align perfectly with your brand.
                            </p>
                          </li>
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className=" mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Flexible commitment: Embrace our monthly rolling plan with no long-term contracts, granting you the freedom to adapt as needed.
                            </p>
                          </li>
                        </ul>
                        <p className="text-base text-ninjaml relative pl-3">
                          <span className="font-light text-lg">£</span>
                          <span className="text-2xl font-semibold">399 + VAT</span>
                          <span className="text-gray-600 font-light text-lg">
                            /month
                          </span>
                        </p>
                        <a href="https://wa.me/+923360003866?text=👋%20I'm%20interested%20in%20the%20Axis%20Alpha%20Standard%20Social%20Media%20plan.">
                        <button className="mt-5 w-full bg-ninjaml hover:bg-ninjamd focus:outline-none transition duration-150 ease-in-out rounded text-white px-8 py-3 text-base font-semibold py-3">
                          Choose
                        </button></a>
                      </div>
                    </motion.div>
                  </div>

                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                  <motion.div
                      initial={{ opacity: 0, y: 100 }} // Initial state: invisible and shifted down
                      animate={{ opacity: 1, y: 0 }} // Animation state: fade in and move up
                      transition={{ duration: 3 }}
                    >
                    <div className="py-5 px-4 bg-ninjasl border border-gray-200 shadow rounded-lg text-left">
                      <h4 className="text-2xl text-white font-semibold pb-8">
                        Pro
                      </h4>
                      <ul className="flex flex-col mb-6">
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          Digital Marketing Strategy: Our senior marketing professionals will provide a comprehensive marketing strategy for your business. The plan will include competitor analysis, audience segmentation, SWOT analysis, current website and social media standing, email performance and optimization.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          Email Marketing: Get access to email marketing professionals that will not only enhance the size of your database with lead gen campaigns but also ensure the right message is delivered to your existing database. We take pride in ranking above the industry benchmarks for email open and click rates.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          Performance Marketing: Our team of skilled online advertisers will launch ad campaigns tailored to your goals and audience. Ensuring a high ROI, our advertisers are skilled in writing ad copies and making eye-catching graphics that appeal to the end user and get maximum conversion.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          1x Dedicated Account Managers that will facilitate the project completion and will report to you on a daily basis. 
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4 "
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          Social Media Management: Benefit from 5x impactful social media posts per week, keeping your audience captivated on all the social media channels including FB, Insta, Twitter and LinkedIn. 
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          Content Writing: Our skilled content writing team will handle your website copy, blog posts, articles, and social media copy.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          Graphic Design: Our team of skilled graphic designers will create custom graphics that align perfectly with your brand.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          Flexible commitment: Embrace our monthly rolling plan with no long-term contracts, granting you the freedom to adapt as needed.
                          </p>
                        </li>
                      </ul>
                      <p className="text-base text-white relative pl-3">
                        <span className="font-light text-lg">£</span>
                        <span className="text-2xl font-semibold">699 + VAT</span>
                        <span className="font-light text-lg">/month</span>
                      </p>
                      <a href="https://wa.me/+923360003866?text=👋%20I'm%20interested%20in%20the%20Axis%20Alpha%20Pro%20Social%20Media%20plan.">
                      <button className="mt-5 w-full text-ninjasl focus:outline-none transition duration-150 ease-in-out rounded bg-white hover:bg-ninjasd px-8 py-3 text-base font-semibold py-3">
                        Choose
                      </button></a>
                    </div>
                    </motion.div>
                  </div>
                  {/* <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                    <div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                      <h4 className="text-2xl text-indigo-700 font-semibold pb-8">
                        Enterprise
                      </h4>
                      <ul className="flex flex-col mb-6">
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            24/7 access
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Order labs + Results
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Radiology tests + Results
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Partnership + Discounts
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Direct doctor phone number
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Specialists appoinments
                          </p>
                        </li>
                      </ul>
                      <p className="text-base text-indigo-700 relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">200</span>
                        <span className="font-light text-lg">/month</span>
                      </p>
                      <button className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-indigo-700 px-8 py-3 text-base font-semibold py-3">
                        Choose
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
              <style
                dangerouslySetInnerHTML={{
                  __html: "",
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <style>
        {` 
            .checkbox:checked {
                right: 0;
                background-color: #4338ca;
            }
            `}
      </style>
    </div>
  );
}

import Banner from "../components/Homebanner";
import Cards from "../components/Cards";
import LeftpicSection from "../components/LeftpicSection";
import Numbers from "../components/Numbers";
import Zigzag from "../components/Zigzag";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import Form from "../components/Form";
import Textsimsec from "../components/Textsimsec";
import RocketIcon from "../assets/rocket.svg";
import LeftPic from "../assets/leftpic.png";
import Cheap from "../assets/cheap.png"
import Expert from "../assets/expert.png"
import Relax from "../assets/relax.png"
import Social from "../assets/social.gif"
import Catchy from "../assets/catchy.gif"
import {InfiniteSlider} from "../components/InfiniteSlider"

export default function Home() {
  const bannerHead ="Your Digital Partner"
  const bannerText = "We provide cost-effective social media management services aimed at amplifying your brand's visibility and generating sales."
  const bannerButton ="Get Started"

  const cImg1 = Expert
  const cHead1 = "Experts in the realm of social media."
  const cText1 = "Harnessing the power of social media is crucial for effective online business promotion, yet it demands both expertise and dedicated effort. With over a decade of marketing experience, our team possesses the knowledge and skills needed to excel in the realm of social media marketing."
  const cImg2 = Cheap
  const cHead2 = "Budget-friendly solutions without breaking the bank."
  const cText2 = "We understand that marketing expenses can often be a concern, which is why we take pride in offering affordable social media management solutions. Our plans start at just £89 plus VAT per month, ensuring that even small businesses can access our services without straining their budgets."
  const cImg3 = Relax
  const cHead3 = "Hassle-free experience."
  const cText3 = "We understand that your time is valuable and that creating new content and engaging with an audience on social media can be time-consuming. If you prefer not to handle these tasks or simply don't have the time, you can rely on us to manage your social media accounts effectively."
  
  const lPicImg =LeftPic
  const lPicHead = "Experience cost-effective social media management 💻"
  const lPicText = "Irrespective of whether you operate a car garage or a wedding business, the impact of social media cannot be overlooked. If you're considering expanding your online presence and incorporating social media into your marketing mix, allow the dedicated team at Axis Alpha to alleviate the burden of managing your social media channels.  Our service packages, tailored specifically for small businesses, start from as little as £89+VAT per month. This ensures affordability without compromising the quality and effectiveness of our services."
  const lPicButton = "Get in Touch"

  const numHead ="By The Numbers 📊"
  const numText ="Numbers get us excited. We're passionate about growth, and about helping you find more clients from your social media channels."
  const numF1 ="45000"
  const numFT1 ="Followers we've helped our clients gain"
  const numF2 ="120%"
  const numFT2 ="Average engagement growth per year"

  const th ="A growing list of satisfied clients 👍"
  const ti1 ="https://i.pravatar.cc/100?img=1"
  const tn1 ="Dave"
  const tm1 ="Entrusting my social media to Axis Alpha Consulting frees up 3 hours per week, allowing me to focus on product development. It's a relief to have fewer concerns on my plate!"
  const ti2 ="https://i.pravatar.cc/100?img=2"
  const tn2 ="Marc"
  const tm2 ="Managing social media is a challenge with my busy schedule, but I understand its significance. Axis Alpha Consulting provides both affordability and professionalism, making it a valuable solution. Grateful for their services!"
  const ti3 ="https://i.pravatar.cc/100?img=3"
  const tn3 ="Crafts Shop Owner"
  const tm3 ="Letting professionals like Axis Alpha Consulting take over my digital crafts shop has been an excellent decision. I’ve seen my reach and conversions go up, while I can spare more time working of the products"
  const ti4 ="https://i.pravatar.cc/100?img=4"
  const tn4 ="Bedding Shop Owner"
  const tm4 ="I decided to give Axis Alpha a go because of their extremely affordable services. I have been able to scale up by home textile shop by 39% since I started using their services 7 weeks ago"
  const ti5 ="https://i.pravatar.cc/100?img=5"
  const tn5 ="Ali"
  const tm5 ="Axis Alpha has completely transformed our online presence. The team’s expertise in SEO and content creation increased search rankings for my business organically. I would highly recommend for all their services"
  const ti6 ="https://i.pravatar.cc/100?img=6"
  const tn6 ="Harrison"
  const tm6 ="Community Management service has been a game changer for my social media handles. A devoted individual looking after my social media needs 24/7 means I get more in terms of customer satisfaction leading to higher customer retention. Highly recommended"

  const zzi1 = Social
  const zzh1 ="Our content is tailored to captivate your customers"
  const zzt1 ="Our approach involves thorough research of your industry, enabling us to curate content that resonates with your followers and expands your brand's reach to a broader audience. We are committed to producing meaningful and tailored content that goes beyond generic or superficial material. Each piece of content we create is thoughtfully crafted, ensuring its relevance and value to your target audience. Our focus is on delivering content that captivates and drives meaningful engagement."
  const zzi2 = Catchy
  const zzh2 ="Visually stunning graphics that make you shine"
  const zzt2 ="As part of our packages, we offer captivating custom graphics designed exclusively for your business. These graphics are meticulously created to reflect your brand's identity, incorporating your unique colors, fonts, logos, and overall visual aesthetics. Our goal is to present your brand in a visually appealing and socially-savvy manner, ensuring that the graphics align with your brand's identity while resonating with your target audience across various social media platforms."
  
  const tssh ="Affordable pricing options that won't break the bank."
  const tsst ="…however, our affordability does not imply any compromise on the quality of our service. On average, hiring a social media executive would cost you more than £1,800 per month, and other agencies offering similar social media management services typically charge a minimum of £500 per month. In contrast, we provide a sensible choice for businesses seeking professional social media management that not only fits their budget but also delivers tangible results. With us, you can expect a high standard of service without the exorbitant costs associated with other options in the market."
  
  const faqh ="Questions? 🤔 We've got the answers…"
  const faq1 ="Do you offer refunds?"
  const faqa1 ="Unfortunately, we do not offer refunds once we begin social media campaigns.We’re super confident that you’ll love what we do, and if you’re not, we’ll work hard to put things right. Before we get started, we’ll put together a week’s draft for you to review and provide feedback. At this time, you’re free to cancel if you’re not satisfied with the quality of the content. Simply let us know and we’ll make sure your direct debit goes through."
  const faq2 ="How do payments work??"
  const faqa2 ="We know that circumstances change and there's not always a budget for marketing. We charge our clients up front for the month ahead; you can then decide whether or not to renew at the end of the month, taking breaks and coming back as and when required."
  const faq3 ="Do you offer other marketing services?"
  const faqa3 ="We do! Whether you’re looking for content writing, link building, search engine optimization, email marketing, web design, web maintenance, or public relations, please get in touch. Several clients have started out using our social media services and then look to us for the rest of their marketing needs – we’d love to work with you on a bespoke marketing campaign."
  const faq4 ="Can I upgrade/downgrade?"
  const faqa4 ="Absolutely. A lot of our clients start out on the £99 per month social media package and then switch to another package when they start to see results. Please let us know before the start of your next billing date if you’d like to upgrade/downgrade, and we can take it from there."
  const faq5 = "Can I still post on my accounts when you’re managing them?"
  const faqa5 = "We actively encourage our clients to stay engaged with their social media channels, respond to comments and questions from customers, and post on top of what we do. After all, the more quality content you post on social media, the better the response you’re going to get. Whether you’re attending an event, receiving a new delivery of stock at your store, or you simply fancy a chin-wag with your followers, go for it. It’s your account and business – we’re simply there to offer a helping hand and ‘top up’ the channels when you’re too busy! If you’re worried about posting at the same time as us or repeating what we’re going to post, get in touch and we’ll give you an overview of this week’s scheduled posts."
  const faq6 ="Are my social media login details safe when I send them to Axis Alpha Consulting?"
  const faqa6 = "For most clients, we won’t need your social media login details – instead, we’ll ask you to connect your channels to our scheduling tool, where we can post on your behalf. In some circumstances, we’ll need direct access. We recommend that you send passwords via an encryption tool. Once we have your details, we’ll keep them locked in a secure vault (LastPass), which is encrypted and protected with two-factor authentication. If you choose to cancel your plan or take a break from Axis Alpha Consulting, we’ll remove your passwords right away (but we also recommend changing them)."
  const faq7 = "What information do you need before we get started?"
  const faqa7 = "-Business Background: We will often ask for information about your business, such as its name, industry, target audience, products or services offered, unique selling propositions, and any existing marketing strategies or campaigns. -Goals and Objectives: It's important to communicate your marketing goals and objectives to us. This includes outlining what you aim to achieve through marketing efforts, whether it's increasing brand awareness, driving website traffic, generating leads, boosting sales, or improving customer engagement. -Target Audience: We need a clear understanding of your target audience. This includes demographic information such as age, gender, location, and interests, as well as psychographic details like values, behaviors, and pain points. The more information you provide about your target audience, the better the marketing strategies can be tailored to reach them effectively."
  const faq8 ="Will you sign up for accounts on social media for me?"
  const faqa8 = "If you don’t have an account on a particular social network or you’d like to start from scratch, we can create one for you. It’d be great if you could send across some assets like your company logo and any images, which we can use to create a header image and social media avatar. Once done, we’ll send over the login details or make you an admin."
  
  return (
    <div className="bg-ninjald">
      <Banner cImg1={cImg1} cHead1={cHead1} cText1={cText1} cImg2={cImg2} cHead2={cHead2} cText2={cText2} cImg3={cImg3} cHead3={cHead3} cText3={cText3} />
      {/* <LeftpicSection lPicImg={lPicImg} lPicHead={lPicHead} lPicText={lPicText} lPicButton={lPicButton} /> */}
      <Numbers numHead={numHead} numText={numText} numF1={numF1} numFT1={numFT1} numF2={numF2} numFT2={numFT2} />
      {/* <Cards cImg1={cImg1} cHead1={cHead1} cText1={cText1} cImg2={cImg2} cHead2={cHead2} cText2={cText2} cImg3={cImg3} cHead3={cHead3} cText3={cText3} /> */}
      {/* <Testimonials th={th} ti1={ti1} tn1={tn1} tm1={tm1} ti2={ti2} tn2={tn2} tm2={tm2} ti3={ti3} tn3={tn3} tm3={tm3} ti4={ti4} tn4={tn4} tm4={tm4} ti5={ti5} tn5={tn5} tm5={tm5} ti6={ti6} tn6={tn6} tm6={tm6} /> */}
      <InfiniteSlider/>
      <Zigzag zzi1={zzi1} zzh1={zzh1} zzt1={zzt1} zzi2={zzi2} zzh2={zzh2} zzt2={zzt2} />
      
      <Textsimsec tssh={tssh} tsst={tsst} />
      <Faq faqh={faqh} faq1={faq1} faqa1={faqa1} faq2={faq2} faqa2={faqa2} faq3={faq3} faqa3={faqa3} faq4={faq4} faqa4={faqa4} faq5={faq5} faqa5={faqa5} faq6={faq6} faqa6={faqa6} faq7={faq7} faqa7={faqa7} faq8={faq8} faqa8={faqa8} />
      <Form />
    </div>
  );
}

export default function Faq({faqh,faq1,faqa1,faq2,faqa2,faq3,faqa3,faq4,faqa4,faq5,faqa5,faq6,faqa6,faq7,faqa7,faq8,faqa8}) {
  return (
    <section className="bg-ninjaml dark:text-gray-100">
      <div className="container flex flex-col justify-center p-4 mx-auto md:p-8">
      <h2 className="text-2xl font-bold sm:text-3xl text-center pb-5 text-white">
      Frequently Asked Questions
              </h2>
        {/* <h2 className="mb-12 text-4xl font-bold leadi text-center sm:text-5xl">
          Frequently Asked Questions
        </h2> */}
        <div className="grid gap-10 md:gap-8 sm:p-3 md:grid-cols-2 lg:px-12 xl:px-32">
          <div>
            <h3 className="font-extrabold text-white">{faq1}</h3>
            <p className="mt-1 text-white">
              {faqa1}
            </p>
          </div>
          <div>
            <h3 className="font-extrabold text-white">{faq2}</h3>
            <p className="mt-1 text-white">
              {faqa2}
            </p>
          </div>
          <div>
            <h3 className="font-extrabold text-white">{faq3}</h3>
            <p className="mt-1 text-white">
              {faqa3}
            </p>
          </div>
          <div>
            <h3 className="font-extrabold text-white">{faq4}</h3>
            <p className="mt-1 text-white">
              {faqa4}
            </p>
          </div>
          <div>
            <h3 className="font-extrabold text-white">{faq5}</h3>
            <p className="mt-1 text-white">
              {faqa5}
            </p>
          </div>
          <div>
            <h3 className="font-extrabold text-white">{faq6}</h3>
            <p className="mt-1 text-white">
              {faqa6}
            </p>
          </div>
          <div>
            <h3 className="font-extrabold text-white">{faq7}</h3>
            <p className="mt-1 text-white">
              {faqa7}
            </p>
          </div>
          <div>
            <h3 className="font-extrabold text-white">{faq8}</h3>
            <p className="mt-1 text-white">
              {faqa8}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

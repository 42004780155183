
export default function Cards({cImg1,cHead1,cText1,cImg2,cHead2,cText2,cImg3,cHead3,cText3}) {
  return (
    <>
      <div className="bg-ninjald -mt-10 mx-auto px-4 py-8 space-y-4 md:px-8 lg:px-16 overflow-x-hidden">
        <div className="  grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          <div className="rounded-2xl  p-1 shadow-xl">
            <a className="block rounded-xl  p-4 sm:p-6 lg:p-8">
              <div className="mt-16">
                <div className="App flex justify-center items-center">
                  <img src={cImg1} alt="icon " />
                </div>
                <h3 className="text-lg font-bold text-ninjaml sm:text-xl text-center">
                  {cHead1}
                </h3>
                <p className="mt-2 text-sm text-ninjaml text-center">
                  {cText1}
                </p>
              </div>
            </a>
          </div>

          <div className="rounded-2xl  p-1 shadow-xl">
            <a className="block rounded-xl  p-4 sm:p-6 lg:p-8">
              <div className="mt-16">
                <div className="App flex justify-center items-center">
                  <img src={cImg2} alt="React Logo" />
                </div>
                <h3 className="text-lg font-bold text-ninjaml sm:text-xl text-center">
                  {cHead2}
                </h3>
                <p className="mt-2 text-sm text-ninjaml text-center">
                  {cText2}
                </p>
              </div>
            </a>
          </div>

          <div className="rounded-2xl  p-1 shadow-xl">
            <a className="block rounded-xl p-4 sm:p-6 lg:p-8">
              <div className="mt-16">
                <div className="App flex justify-center items-center">
                  <img src={cImg3} alt="React Logo" />
                </div>
                <h3 className="text-lg font-bold text-ninjaml sm:text-xl text-center">
                  {cHead3}
                </h3>
                <p className="mt-2 text-sm text-ninjaml text-center">
                  {cText3}
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

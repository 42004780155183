export default function Textsimsec({tssh,tsst}) {
  return (
    <section class="bg-white text-white">
      <div class="mx-auto max-w-screen-xl px-4 py-10 lg:flex lg:h-screen lg:items-center ">
        <div class="mx-auto max-w-3xl text-center rounded border-4 border-dashed p-10 md:p-20 border-ninjamd relative">

        <div class="absolute -top-3 -right-3 -mr-1 -mt-1 w-2 h-2 md:w-7 md:h-7 rounded-full bg-ninjaml animate-ping"></div>
        <div class="absolute -top-3 -right-3 -mr-1 -mt-1 w-2 h-2 md:w-7 md:h-7 rounded-full bg-ninjaml"></div>
          
          <h1 class=" bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
            <span class="sm:block text-ninjaml">
              {" "}
              Cost Effective Solutions{" "}
            </span>
          </h1>

          <p class="mx-auto mt-4 max-w-xl sm:text-xl/relaxed text-ninjaml">
            We understand that digital service expenses can often be a concern, which is why we take pride in providing affordable solutions. Our plans start at just £100 plus VAT per month, ensuring that even small businesses can access our services without budget constraints.
          </p>
        </div>
      </div>
    </section>
  );
}

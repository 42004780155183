import { motion } from "framer-motion";
export default function InfoPricing() {
  return (
    <div>
      <section className="p-6  bg-ninjaml">
        <div className=" mx-auto container">
          <div className="flex flex-col lg:items-center justify-center w-full">
            <h1 className="font-semibold text-white text-3xl md:text-4xl">
            {/* Learn how we can help solve your most pressing workforce challenges. */}
            </h1>
            <p className="mt-2.5 lg:w-1/2 lg:text-center text-2xl">
            Learn how we can help solve your most pressing workforce challenges.
            </p>
          </div>
          <div className="flex items-center justify-center w-full">
            <div className="pt-14">
              <div className="container mx-auto">
                <div className="flex flex-wrap mb-12 justify-between sm:justify-center -mx-6">
                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                    <motion.div
                      whileInView={{ opacity: [0,1], y: [100,0] }} 
                      transition={{ duration: 1 }}
                    >
                      <div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                        <h4 className="text-2xl text-ninjasl font-semibold pb-8 text-center">
                        Human Resources Solutions
                        </h4>
                        <p>Revolutionize your hiring process with our premier Hiring Solutions:</p>
                        <ul className="flex flex-col mb-6">
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className="mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Tap into our extensive global talent network to secure top-tier employees.
                            </p>
                          </li>
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className="mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Enjoy swift and effortless access to a pool of high-quality, pre-screened talent.                            </p>
                          </li>
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className="mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Rest assured with our guarantee of 100% English fluency among our candidates.                            </p>
                          </li>
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className=" mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Experience the convenience of hand-picked matches tailored precisely to your unique requirements.                            </p>
                          </li>
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className="mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-800 text-base font-normal">
                            Flexible commitment: Embrace our monthly rolling plan with no long-term contracts, granting you the freedom to adapt as needed.
                            </p>
                          </li>
                          <li className="flex items-center mb-2.5">
                            <img
                              src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                              className="opacity-0 mr-4"
                              alt="check-mark"
                            />
                            <p className="text-gray-400 text-base font-normal">
                            
                            </p>
                          </li>
                        </ul>
                        <p className="text-base text-ninjasl relative pl-3 text-center">
                          {/* <span className="font-light text-lg">£</span> */}
                          <span className=" font-semibold ">Unlock the potential of our Hiring Solutions and streamline your recruitment process like never before.</span>
                          <span className="text-gray-600 font-light text-lg">
                            {/* /month */}
                          </span>
                        </p>
                        {/* <button className="mt-5 w-full bg-ninjaml hover:bg-ninjamd focus:outline-none transition duration-150 ease-in-out rounded text-white px-8 py-3 text-base font-semibold py-3">
                          Choose
                        </button> */}
                      </div>
                    </motion.div>
                  </div>

                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                  <motion.div
                      
                      whileInView={{ opacity: [0,1], y: [100,0] }} 
                      transition={{ duration: 2 }}
                    >
                    <div className="py-5 px-4 bg-ninjasl border border-gray-200 shadow rounded-lg text-left">
                      <h4 className="text-2xl text-white font-semibold pb-8 text-center">
                      Employee Training & Onboarding
                      </h4>
                      <p className="text-white">We guarantee seamless and efficient integration for your new employees.</p>
                      <ul className="flex flex-col mb-6">
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          With our proprietary training program, they come pre-trained.                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          Each remote employee is equipped with all the necessary technology and equipment.                          </p>
                        </li>
                        {/* <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          Instagram spotlight: Shine on Instagram with 10 weekly posts, captivating your followers with visually stunning content.
                          </p>
                        </li> */}
                        {/* <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          Visual brilliance: Our team of skilled graphic designers will create captivating custom graphics that reflect your unique brand.
                          </p>
                        </li> */}
                        {/* <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4 "
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          Personalized assistance: Enjoy the expertise of a dedicated account manager who will provide prompt support via email and WhatsApp, ensuring seamless communication.
                          </p>
                        </li> */}
                        {/* <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                          Flexible and hassle-free: Embrace our monthly rolling plan with no long-term contracts, giving you the freedom to adapt and adjust your strategy as needed.
                          </p>
                        </li> */}
                      </ul>
                      <p className="text-base text-white relative pl-3 text-center">
                        {/* <span className="font-light text-lg">£</span> */}
                        <span className="font-semibold">Rest assured that our comprehensive approach ensures a smooth onboarding experience, allowing your new employees to hit the ground running.</span>
                        {/* <span className="font-light text-lg">/month</span> */}
                      </p>
                      {/* <button className="mt-5 w-full text-ninjasl focus:outline-none transition duration-150 ease-in-out rounded bg-white hover:bg-ninjasd px-8 py-3 text-base font-semibold py-3">
                        Choose
                      </button> */}
                    </div>
                    </motion.div>
                  </div>
                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                  <motion.div
                      whileInView={{ opacity: [0,1], y: [100,0] }} 
                      transition={{ duration: 3 }}
                    >
                    <div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                      <h4 className="text-2xl text-ninjasl font-semibold pb-8 text-center">
                      Administer & Assist
                      </h4>
                      <p>Leave the employee management tasks to us:</p>
                      <ul className="flex flex-col mb-6">
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                          Streamlined benefits administration
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                          Efficient payroll management
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                          Meticulous compliance handling
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                          Reliable IT support
                          </p>
                        </li>
                        {/* <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Direct doctor phone number
                          </p>
                        </li> */}
                        {/* <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Specialists appoinments
                          </p>
                        </li> */}
                      </ul>
                      <p className="text-base text-ninjasl relative pl-3 text-center">
                        {/* <span className="font-light text-lg">$</span> */}
                        <span className=" font-semibold ">With our comprehensive services, you can trust us to expertly handle all aspects of employee management, ensuring smooth operations and peace of mind for your business.</span>
                        {/* <span className="font-light text-lg">/month</span> */}
                      </p>
                      {/* <button className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-indigo-700 px-8 py-3 text-base font-semibold py-3">
                        Choose
                      </button> */}
                    </div>
                    </motion.div>
                  </div>
                </div>
              </div>
              <style
                dangerouslySetInnerHTML={{
                  __html: "",
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <style>
        {` 
            .checkbox:checked {
                right: 0;
                background-color: #4338ca;
            }
            `}
      </style>
    </div>
  );
}

import React from "react";
import { motion } from "framer-motion";
import Testvid from "../assets/3test.mp4";
import Mvid from "../assets/3testm.mp4"
import AAlogo from "../assets/logo512.png"

export default function Banner({ image, bannerHead, bannerText, bannerText2 }) {
  return (
    <section class="bg-ninjald text-white ">
      <div className=" ">
      <motion.div
        initial={{ background: 'linear-gradient(to right, #0096C7, #03045E)' }}
        animate={{ background: 'linear-gradient(to right, #03045E, #0096C7)' }}
        transition={{ duration: 3, yoyo: Infinity }}
        className="  "
      >
        
        {/* <motion.div
        initial={{ background: 'linear-gradient(to right, #3B1D4A, #6B1650)' }}
        animate={{ background: 'linear-gradient(to right, #6B1650, #3B1D4A)' }}
        transition={{ duration: 3, yoyo: Infinity }}
        className="absolute w-full h-full"
      > */}
        <div class="mx-auto max-w-screen-xl px-4 py-32 lg:flex  lg:items-center  ">
          <div class="mx-auto max-w-3xl text-center">
          <motion.div
                      whileInView={{ opacity: [0,1], y: [100,0] }} 
                      transition={{ duration: 1 }}
                    >
          <div className="App flex justify-center items-center ">
                    <img src={AAlogo} alt="icon " className="w-60" />
                  </div></motion.div>
            <h1 class=" bg-clip-text text-2xl font-bold text-transparent sm:text-5xl">
              <span class="sm:block text-white"> {bannerHead} </span>
            </h1>

            <p class="mx-auto mt-4 max-w-xl sm:text-xl/relaxed text-white text-center">
              {bannerText}
            </p>
            <p class="mx-auto mt-4 max-w-xl sm:text-xl/relaxed text-white text-center">
              {bannerText2}
            </p>

            <div class="mt-8 flex flex-wrap justify-center gap-4">
              {/* <a
              class="block w-full rounded border border-black-600 bg-ninjamd px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
              href="/get-started"
            >
              {bannerButton}
            </a> */}
            </div>
          </div>
        </div>
        </motion.div>
      </div>
    </section>
  );
}

import { motion } from "framer-motion";
import { animate } from "framer-motion";
export default function Zigzag({zzi1,zzh1,zzt1,zzi2,zzh2,zzt2}) {
  return (
    <section className="overflow-x-hidden">
      <div className="bg-ninjaml mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className=" grid grid-cols-1  lg:grid-cols-2 ">
          <motion.div
            transition={{ duration: 1 }}
            whileInView={{ x: [-100, 0] }}
            className="hidden lg:block relative h-80 overflow-hidden sm:h-80 lg:h-full"
          >
            <img
              alt="Party"
              src={zzi1}
              className="absolute inset-0 object-cover"
            />
          </motion.div>

          <div className="lg:py-16 p-5">
            <article className="space-y-4 text-white">
              <h2 className="text-2xl font-bold sm:text-3xl">
                {zzh1}
              </h2>

              <p>
                {zzt1}
              </p>
            </article>
          </div>
        </div>

        <div className=" grid grid-cols-1  lg:grid-cols-2 ">
          <div className="lg:py-16 p-5">
            <article className="space-y-4 text-white">
              <h2 className="text-2xl font-bold sm:text-3xl">
                {zzh2}
              </h2>

              <p>
                {zzt2}
              </p>
            </article>
          </div>
          <motion.div
            transition={{ duration: 1 }}
            initial={{ x: 0 }}
            whileInView={{ x: [100, 0] }}
            className="hidden lg:block relative h-80 overflow-hidden sm:h-80 lg:h-full"
          >
            <img
              alt="Party"
              src={zzi2}
              className="absolute inset-0 h-full w-full object-cover"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
}

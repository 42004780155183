import LeftpicSection from "../components/LeftpicSection";
import WDzigzag from "../components/LGzigzag";
import Webdevp from "../assets/webdev.webp"
import Asone from "../assets/asone.webp"
import Pixel from "../assets/pixel.webp"
import Bespoke from "../assets/bespoke.webp"
import Form from "../components/Form";
import Cards from "../components/Cards";
import Monthly from "../assets/monthly.webp"
import Flexible from "../assets/flexible.webp"
import Dedicated from "../assets/dedicated.webp"
import Leadgen from "../assets/Unleash the Lead Magnet Attract & Convert.svg"
export default function Webdev(){

    const lPicImg = Leadgen
    const lPicHead = "Lead Generation"
    const lPicText = "Crickets chirping on your landing page? Not anymore. We unleash irresistible campaigns and targeted strategies that transform your website into a lead magnetizing powerhouse."
    const lPicButton = "Get in Touch"

    const zzi1 = Pixel
    const zzh1 ="Enter Our World Of Lead Generation Sorcery"
    const zzt1 ="We're not just talking about throwing spaghetti at the wall and hoping something sticks. We're talking about targeted strategies, irresistible campaigns, and data-driven insights that turn your website into a lead magnet so powerful, it practically hums with electricity. ⚡️"
    const zzi2 = Bespoke
    const zzh2 ="Bespoke Web Development Services All Over UK"
    const zzt2 ="We understand the importance of clarity in communication when it comes to website development, which is why we strive to simplify the process for you. Our web development agency in the UK excels in creating websites that not only deliver flawless functionality and captivating aesthetics but also prioritize robust digital security. We will guide you through our strategic approach to help your company achieve its desired goals and objectives, revolutionizing your business and expanding its visibility to a wider pool of potential customers."
    const zzi3 = Asone
    const zzh3 = "Web Development and Design as One"
    const zzt3 ="Exceptional web design alone is insufficient. We recognize the significance of delivering a comprehensive service that seamlessly merges the finest aspects of web development and design. Not only will your website exhibit stunning aesthetics, but it will also operate flawlessly. Our team of UK web developers will establish a digital platform that enables your customers to engage with you effectively, regardless of the device or platform they choose, be it a web browser on a PC, a mobile device, or any other medium. Wherever your audience discovers you, we are committed to ensuring their journey is seamless, resulting in a profound affinity for your brand."

    const cImg1 = Dedicated
    const cHead1 = "Dedicated manager"
    const cText1 = "Our highly skilled professional will be dedicated to serving your technical and marketing needs as valuable member of your workforce. Acting as your full-time employee, they will be meticulously supervised to guarantee optimal performance and adherence to your specific requirements."
    const cImg2 = Monthly
    const cHead2 = "No monthly contracts"
    const cText2 = "With our Talent Acquisition services, you have the flexibility to pay according to the hours. There are no binding contracts or rigid plans involved. You can choose to purchase engagement time in convenient blocks or set up a retainer arrangement that suits your needs. This allows you to have control over your budget and ensures clear communication regarding the services provided."
    const cImg3 = Flexible
    const cHead3 = "We're pretty flexible"
    const cText3 = "Tell us your requirement, whether it's website developer, marketing analytics specialist or Social media manager. We'll follow your guidance and provide you with the best resource."
    


    return(
        <div className="bg-ninjald">
        <LeftpicSection lPicImg={lPicImg} lPicHead={lPicHead} lPicText={lPicText} lPicButton={lPicButton} />
        <WDzigzag zzi1={zzi1} zzh1={zzh1} zzt1={zzt1} zzi2={zzi2} zzh2={zzh2} zzt2={zzt2}  />
        <Cards cImg1={cImg1} cHead1={cHead1} cText1={cText1} cImg2={cImg2} cHead2={cHead2} cText2={cText2} cImg3={cImg3} cHead3={cHead3} cText3={cText3} />
        <Form /> 
        </div>
    )
}

import Banner from "../components/Banner";
import Faq from "../components/Faq";
import Form from "../components/Form";
import Pricing from "../components/Pricing";
import Cards from "../components/Cards";
import Zigzag from "../components/Zigzag";
import Blog from "../assets/blog.webp"
import Quality from "../assets/quality.webp"
import Resell from "../assets/resell.webp"
import Copy from "../assets/copywriting.webp"
import Growth from "../assets/growth.webp"
import Imgb from "../assets/i5.png"
export default function Copywriting() {
  const imgb = Imgb
  const bannerHead ="Copywriting."
  const bannerText = "At Axis Alpha, we provide affordable copywriting services starting at just £29 per blog post. Our pricing structure is designed to offer you cost-effective solutions without compromising on the quality of our work."
  const bannerButton ="Get Started"

  const cImg1 = Quality
  const cHead1 = "Expert UK-based writers"
  const cText1 = "We take pride in handling everything in-house, without outsourcing our content to third parties. This ensures that you receive the highest quality content every time you place an order with us. You can have full confidence in the expertise and dedication of our team."
  const cImg2 = Blog
  const cHead2 = "Tailored for blog posts"
  const cText2 = "Our content writing services are tailored to fulfill your specific needs, whether you require regular updates for your blog or are focused on a link-building campaign. We provide high-quality content that aligns with your requirements and supports your goals effectively."
  const cImg3 = Resell
  const cHead3 = "Perfect for resellers"
  const cText3 = "Resell our white-label copywriting services to your clients as a marketing agency, maintaining a seamless and branded experience throughout."
  
  const zzi1 = Copy
  const zzh1 ="Content optimized to drive both social traction and sales performance"
  const zzt1 ="At Axis Alpha, we recognize the significance of exceptional copywriting. By incorporating a blog on your website and populating it with engaging and relevant content, you can distinguish yourself from your competitors, both locally and nationally. Furthermore, a robust blog provides a wealth of material to share on social media, driving traffic back to your website and equipping your followers and customers with valuable resources to share with their networks. This amplifies your online presence and fosters organic growth through word-of-mouth referrals."
  const zzi2 = Growth
  const zzh2 ="Develop trustworthiness, raise awareness, and advance in ranks"
  const zzt2 ="Implementing an effective content marketing strategy establishes your authority within your niche. For instance, consistently publishing informative blog posts about tropical fish species and care will attract a loyal audience who will return for more valuable insights. When they are ready to make a purchase, your brand will be top of mind. Additionally, well-written blog posts and webpages contribute to higher rankings for competitive keywords on search engines like Google, giving you an edge over your competition."
  
  return (
    <>
      <Banner image={imgb} bannerHead={bannerHead} bannerText={bannerText} bannerButton={bannerButton} />
      <Cards cImg1={cImg1} cHead1={cHead1} cText1={cText1} cImg2={cImg2} cHead2={cHead2} cText2={cText2} cImg3={cImg3} cHead3={cHead3} cText3={cText3} />
      <Zigzag zzi1={zzi1} zzh1={zzh1} zzt1={zzt1} zzi2={zzi2} zzh2={zzh2} zzt2={zzt2} />
      <Form />
    </>
  );
}

import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import Banner from "./components/Banner";
import Cards from "./components/Cards";
import LeftpicSection from "./components/LeftpicSection";
import Numbers from "./components/Numbers";
import Zigzag from "./components/Zigzag";
import Testimonials from "./components/Testimonials";
import Faq from "./components/Faq";
import Form from "./components/Form";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Community from "./pages/community";
import Webdev from "./pages/webdev";
import Seo from "./pages/seo";
import Copywriting from "./pages/copywriting";
import Socialmedia from "./pages/socialmedia";
import WhatsAppButton from "./components/whatsapp";
import About from "./pages/about";
import Leadgen from "./pages/leadgen"


function App() {
  const faqh ="Questions? 🤔 We've got the answers…"
  const faq1 ="Do you offer refunds?"
  const faqa1 ="Unfortunately, we do not offer refunds once we begin social media campaigns.We’re super confident that you’ll love what we do, and if you’re not, we’ll work hard to put things right. Before we get started, we’ll put together a week’s draft for you to review and provide feedback. At this time, you’re free to cancel if you’re not satisfied with the quality of the content. Simply let us know and we’ll make sure your direct debit goes through."
  const faq2 ="How do payments work??"
  const faqa2 ="We know that circumstances change and there's not always a budget for marketing. We charge our clients up front for the month ahead; you can then decide whether or not to renew at the end of the month, taking breaks and coming back as and when required."
  const faq3 ="Do you offer other marketing services?"
  const faqa3 ="We do! Whether you’re looking for content writing, link building, search engine optimization, email marketing, web design, web maintenance, or public relations, please get in touch. Several clients have started out using our social media services and then look to us for the rest of their marketing needs – we’d love to work with you on a bespoke marketing campaign."
  const faq4 ="Can I upgrade/downgrade?"
  const faqa4 ="Absolutely. A lot of our clients start out on the £99 per month social media package and then switch to another package when they start to see results. Please let us know before the start of your next billing date if you’d like to upgrade/downgrade, and we can take it from there."
  const faq5 = "Can I still post on my accounts when you’re managing them?"
  const faqa5 = "We actively encourage our clients to stay engaged with their social media channels, respond to comments and questions from customers, and post on top of what we do. After all, the more quality content you post on social media, the better the response you’re going to get. Whether you’re attending an event, receiving a new delivery of stock at your store, or you simply fancy a chin-wag with your followers, go for it. It’s your account and business – we’re simply there to offer a helping hand and ‘top up’ the channels when you’re too busy! If you’re worried about posting at the same time as us or repeating what we’re going to post, get in touch and we’ll give you an overview of this week’s scheduled posts."
  const faq6 ="Are my social media login details safe when I send them to Axis Alpha?"
  const faqa6 = "For most clients, we won’t need your social media login details – instead, we’ll ask you to connect your channels to our scheduling tool, where we can post on your behalf. In some circumstances, we’ll need direct access. We recommend that you send passwords via an encryption tool. Once we have your details, we’ll keep them locked in a secure vault (LastPass), which is encrypted and protected with two-factor authentication. If you choose to cancel your plan or take a break from Marketing Ninja, we’ll remove your passwords right away (but we also recommend changing them)."
  const faq7 = "What information do you need before we get started?"
  const faqa7 = "-Business Background: We will often ask for information about your business, such as its name, industry, target audience, products or services offered, unique selling propositions, and any existing marketing strategies or campaigns. -Goals and Objectives: It's important to communicate your marketing goals and objectives to us. This includes outlining what you aim to achieve through marketing efforts, whether it's increasing brand awareness, driving website traffic, generating leads, boosting sales, or improving customer engagement. -Target Audience: We need a clear understanding of your target audience. This includes demographic information such as age, gender, location, and interests, as well as psychographic details like values, behaviors, and pain points. The more information you provide about your target audience, the better the marketing strategies can be tailored to reach them effectively."
  const faq8 ="Will you sign up for accounts on social media for me?"
  const faqa8 = "If you don’t have an account on a particular social network or you’d like to start from scratch, we can create one for you. It’d be great if you could send across some assets like your company logo and any images, which we can use to create a header image and social media avatar. Once done, we’ll send over the login details or make you an admin."
  
  const th ="A growing list of satisfied clients 👍"
  const ti1 ="https://i.pravatar.cc/100?img=1"
  const tn1 ="Kenzie Edgar."
  const tm1 ="Handing over my social media to 99social saves 3 hours a week, which I use to work on products. It's always good to have less to worry about!"
  const ti2 ="https://i.pravatar.cc/100?img=2"
  const tn2 ="Stevie Tifft."
  const tm2 ="I really don't have time to work on social media but I know it's important to maintain a presence. 99social is affordable and professional. Thank you!"
  const ti3 ="https://i.pravatar.cc/100?img=3"
  const tn3 ="Tommie Ewart."
  const tm3 ="Handing over my social media to 99social saves 3 hours a week, which I use to work on products. It's always good to have less to worry about!"
  const ti4 ="https://i.pravatar.cc/100?img=4"
  const tn4 ="Charlie Howse."
  const tm4 ="I really don't have time to work on social media but I know it's important to maintain a presence. 99social is affordable and professional. Thank you!"
  const ti5 ="https://i.pravatar.cc/100?img=5"
  const tn5 ="Nevada Herbertson."
  const tm5 ="Handing over my social media to 99social saves 3 hours a week, which I use to work on products. It's always good to have less to worry about!"
  const ti6 ="https://i.pravatar.cc/100?img=6"
  const tn6 ="Kris Stanton."
  const tm6 ="I really don't have time to work on social media but I know it's important to maintain a presence. 99social is affordable and professional. Thank you!"


  return (
    <>
      <Navbar />
      
      <Routes>
        <Route index element={<Home />} />
        <Route path='contactform' element={<Form/>}/>
        <Route path='about' element={<About/>}/>
        {/* <Route path="testimonials" element={<Testimonials th={th} ti1={ti1} tn1={tn1} tm1={tm1} ti2={ti2} tn2={tn2} tm2={tm2} ti3={ti3} tn3={tn3} tm3={tm3} ti4={ti4} tn4={tn4} tm4={tm4} ti5={ti5} tn5={tn5} tm5={tm5} ti6={ti6} tn6={tn6} tm6={tm6} />
      }/> */}
        <Route path="faqs" element={<Faq faqh={faqh} faq1={faq1} faqa1={faqa1} faq2={faq2} faqa2={faqa2} faq3={faq3} faqa3={faqa3} faq4={faq4} faqa4={faqa4} faq5={faq5} faqa5={faqa5} faq6={faq6} faqa6={faqa6} faq7={faq7} faqa7={faqa7} faq8={faq8} faqa8={faqa8} />
      }/>
        <Route path='talent' element={<Community />} />
        <Route path='webdev' element={<Webdev />} />
        <Route path='seo' element={<Seo/>} />
        <Route path='copywriting' element={<Copywriting/>} />
        <Route path='digitalmarketing' element={<Socialmedia/>}/>
        <Route path='leadgen' element={<Leadgen/>}/>
      </Routes>
      <div>
      {/* Your existing app content */}
      <WhatsAppButton />
    </div>
      <Footer />
    </>
  );
}

export default App;


import Banner from "../components/AboutBanner";
import Faq from "../components/Faq";
import Form from "../components/Form";
import Pricing from "../components/Pricing";
import Cards from "../components/Cards";
import Zigzag from "../components/Zigzag";
import RocketIcon from "../assets/rocket.svg";
import Content from "../assets/content.webp"
import Competition from "../assets/competition.webp"
import Customer from "../assets/customers.webp"
import Seop from "../assets/seo.webp"
import Seog from "../assets/seog.webp"
import Imgb from "../assets/1x1.png"
export default function Seo() {
  const imgb = Imgb
  const bannerHead =""
  const bannerText = "AxisAlpha Consulting was formed in 2016 as a digital solutions consultancy that integrates marketing, web development, SEO, lead generation, and HR to propel businesses. We take pride in having supported clients across 20+ industries, with a dedicated emphasis on transforming businesses within the dynamic global hospitality and tourism sector."
  const bannerText2 = "Global leaders come to us to solve industry-defining challenges in: strategy, marketing, web development, organization, operations, information technology, digital transformation and advanced analytics. Our tailored solutions with dedicated project managers for each client helps structure, orchestrate and enable sustained results."
  

  const cImg1 = Content
  const cHead1 = "Integration Innovation"
  const cText1 = "Experience our seamless solutions, where our capabilities combine with a carefully curated network of the world's top innovators. This synergy results in superior, faster, and more enduring achievements for our clients."
  const cImg2 = Competition
  const cHead2 = "A Culture of Unique Collaboration"
  const cText2 = "Working with us is an exceptional experience. Our team of skilled experts bring forth a distinctive perspective, fostering mutual trust and contagious energy in every client relationship."
  const cImg3 = Customer
  const cHead3 = "Cost-Effective Brilliance"
  const cText3 = "Our commitment to cost-effectiveness ensures that achieving your bold ambitions doesn’t come with a hefty price tag. Unleash the power of innovation without compromising your budget."
  
  const zzi1 =Seop
  const zzh1 ="A wallet-friendly, rational approach to SEO 🌟"
  const zzt1 ="While marketing agencies may charge thousands of pounds for SEO campaigns, you don't have to break the bank to achieve results. Our packages start from just £199 per month, allowing you to compete with rival firms on Google and Bing regardless of your business size. Affordable SEO solutions that deliver effective outcomes are within your reach."
  const zzi2 =Seog
  const zzh2 ="Achieve higher rankings through an authentic and safe process 🌱"
  const zzt2 ="At Axis Alpha Consulting, we prioritize long-term success over quick wins. We are transparent about the fact that SEO requires time and is an investment worth making for businesses looking to decrease their reliance on paid ads. Our tactics are thoroughly tested on real websites, focusing on safe and natural methods to improve rankings. We strictly avoid black hat tactics or short-term strategies, ensuring a sustainable and ethical approach to SEO."
  
  return (
    <>
      <Banner image={imgb} bannerHead={bannerHead} bannerText={bannerText} bannerText2={bannerText2} />
      <Cards cImg1={cImg1} cHead1={cHead1} cText1={cText1} cImg2={cImg2} cHead2={cHead2} cText2={cText2} cImg3={cImg3} cHead3={cHead3} cText3={cText3} />
      {/* <Zigzag zzi1={zzi1} zzh1={zzh1} zzt1={zzt1} zzi2={zzi2} zzh2={zzh2} zzt2={zzt2} /> */}
      {/* <Form /> */}
    </>
  );
}

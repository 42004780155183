import Pricing from "../components/Pricing";
import Banner from "../components/Banner";
import Imgb from "../assets/i1.png"
import Monthly from "../assets/monthly.webp"
import Flexible from "../assets/flexible.webp"
import Dedicated from "../assets/dedicated.webp"
import Cards from "../components/DmCards";
import Form from "../components/Form";
import Msol from "../components/marksol"
import Digm5 from "../components/5digmservices"
import Content from "../assets/content.webp"
import Competition from "../assets/competition.webp"

export default function Socialmedia() {
    const imgb = Imgb
    const bannerHead ="Digital Marketing Excellence"
    const bannerText = "From concept to execution, we prioritize engagement, ensuring that your brand resonates authentically with your audience. Stay ahead in the digital landscape with our innovative approach, where measurable results and strategic insights converge to drive your business to new heights."
    const bannerButton ="Get Started"

    const cImg1 = Dedicated
    const cHead1 = "1. Digital Marketing Strategy and Planning"
    const cText1 = "Our digital marketing strategy and planning delve into meticulous market analysis, audience segmentation, and competitor benchmarking. We craft tailored roadmaps that align with your business objectives, ensuring a targeted and impactful approach. From identifying key performance indicators to forecasting trends, our comprehensive strategy and planning services empower your brand to navigate the digital realm with confidence."
    const cImg2 = Monthly
    const cHead2 = "2. Social Media Management"
    const cText2 = "From crafting engaging content calendars to real-time community engagement, we curate an online persona that resonates with your audience. Our team leverages data-driven insights to optimize posting schedules, refine targeting strategies, and amplify brand visibility across platforms. Whether you seek to build brand loyalty, drive conversions, or foster meaningful connections, our social media management services ensure your brand remains at the forefront of the digital conversation."
    const cImg3 = Flexible
    const cHead3 = "3. Graphic Design"
    const cText3 = "Our creative team excels in translating concepts into captivating visuals, from eye-catching logos to engaging marketing collateral. Whether you need a stunning website interface, impactful marketing materials, or a complete brand overhaul, our graphic design services bring your vision to life with precision and flair."
    const cImg4 = Competition
    const cHead4 = "4. Performance Marketing"
    const cText4 = "Our performance marketing strategies go beyond conventional methods, integrating online ad strategies that maximize ROI. From precision-targeted PPC campaigns to strategically placed display ads, we leverage analytics to optimize every aspect of your online advertising initiatives. Our focus is on achieving measurable results, ensuring that every marketing dollar invested translates into tangible business growth"
    const cImg5 = Content
    const cHead5 = "5. Content Writing"
    const cText5 = "Our team of skilled content creators excels in weaving narratives that captivate audiences and resonate with your brand's essence. Whether it's website content, blog posts, or marketing copy, we infuse every piece with strategic messaging and a unique storytelling flair"


    return(
    <>
    <Banner image={imgb} bannerHead={bannerHead} bannerText={bannerText} bannerButton={bannerButton} />
    {/* <Digm5/> */}
    {/* <Msol/> */}
    <Cards cImg1={cImg1} cHead1={cHead1} cText1={cText1} cImg2={cImg2} cHead2={cHead2} cText2={cText2} cImg3={cImg3} cHead3={cHead3} cText3={cText3} cImg4={cImg4} cHead4={cHead4} cText4={cText4} cImg5={cImg5} cHead5={cHead5} cText5={cText5} />
    
    <Pricing />
    <Form /> 
    </>)
}

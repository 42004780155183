
import Banner from "../components/Banner";
import Faq from "../components/Faq";
import Form from "../components/Form";
import Pricing from "../components/Pricing";
import Cards from "../components/Cards";
import Zigzag from "../components/Zigzag";
import RocketIcon from "../assets/rocket.svg";
import Monthly from "../assets/monthly.webp"
import Flexible from "../assets/flexible.webp"
import Dedicated from "../assets/dedicated.webp"
import Brand from "../assets/brand.webp"
import Engage from "../assets/engaged.webp"
import InfoPricing from "../components/infopricing";
import Imgb from "../assets/i3.png"

export default function Community() {
  const imgb = Imgb
  const bannerHead ="Talent Acquisition Solutions for IT and Marketing experts starting from £600 per Month"
  const bannerText = ""
  const bannerButton ="Get Started"

  const cnImg1 = Dedicated
  const cnHead1 = "Dedicated manager"
  const cnText1 = "Our highly skilled professional will be dedicated to serving your technical and marketing needs as valuable member of your workforce. Acting as your full-time employee, they will be meticulously supervised to guarantee optimal performance and adherence to your specific requirements."
  const cnImg2 = Monthly
  const cnHead2 = "No monthly contracts"
  const cnText2 = "With our Talent Acquisition services, you have the flexibility to pay according to the hours. There are no binding contracts or rigid plans involved. You can choose to purchase engagement time in convenient blocks or set up a retainer arrangement that suits your needs. This allows you to have control over your budget and ensures clear communication regarding the services provided."
  const cnImg3 = Flexible
  const cnHead3 = "We're pretty flexible"
  const cnText3 = "Tell us your requirement, whether it's website developer, marketing analytics specialist or Social media manager. We'll follow your guidance and provide you with the best resource."
  

  const cImg1 = Dedicated
  const cHead1 = "Dedicated manager"
  const cText1 = "Our highly skilled professional will be dedicated to serving your technical and marketing needs as valuable member of your workforce. Acting as your full-time employee, they will be meticulously supervised to guarantee optimal performance and adherence to your specific requirements."
  const cImg2 = Monthly
  const cHead2 = "No monthly contracts"
  const cText2 = "With our Talent Acquisition services, you have the flexibility to pay according to the hours. There are no binding contracts or rigid plans involved. You can choose to purchase engagement time in convenient blocks or set up a retainer arrangement that suits your needs. This allows you to have control over your budget and ensures clear communication regarding the services provided."
  const cImg3 = Flexible
  const cHead3 = "We're pretty flexible"
  const cText3 = "Tell us your requirement, whether it's website developer, marketing analytics specialist or Social media manager. We'll follow your guidance and provide you with the best resource."
  
  const zzi1 = Brand
  const zzh1 ="We'll help you build your brand on social media 🧱"
  const zzt1 ="Creating quality content that sparks conversations and grows your brand is important, but building a thriving social media community requires active user engagement. Our Axis Alpha team specializes in social media community management, serving as your dedicated social media team. We will assist you in maximizing reach, fostering engagement, and cultivating a vibrant online community."
  const zzi2 =Engage
  const zzh2 ="Keep new and existing customers engaged 🤩"
  const zzt2 ="In today's digital landscape, 83% of people expect companies to respond to social media comments within a day or less. We can assist you in maintaining an active presence on important social networking platforms, ensuring timely responses to engage and delight your audience. By being proactive and responsive, you increase your chances of growth, attracting new customers and generating valuable leads."
  
  return (
    <>
      <Banner image={imgb} bannerHead={bannerHead} bannerText={bannerText} bannerButton={bannerButton} />
      <InfoPricing/>
      <Cards cImg1={cImg1} cHead1={cHead1} cText1={cText1} cImg2={cImg2} cHead2={cHead2} cText2={cText2} cImg3={cImg3} cHead3={cHead3} cText3={cText3} />
      {/* <Zigzag zzi1={zzi1} zzh1={zzh1} zzt1={zzt1} zzi2={zzi2} zzh2={zzh2} zzt2={zzt2} /> */}
      <Form />
    </>
  );
}

import Turtle from "../assets/raphael.png";
export default function Footer() {
  return (
    <div className="w-full md:min-h-screen flex items-center justify-center bg-ninjaml pt-5">
      <div className="md:w-2/3 w-full px-4 text-white flex flex-col">
        
        <div className="flex mt-8 flex-col md:flex-row md:justify-between hidden md:block">
          <h1 className="w-full md:w-2/3  text-6xl font-bold">Get in touch</h1>
          <div className="w-44 pt-6 md:pt-0">
            {/* <img src={Turtle} /> */}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex mt-8 mb-12 flex-row justify-between ">
          <a href="digitalmarketing" className="hidden md:block cursor-pointer text-white hover:text-ninjamd uppercase">
            Digital Marketing
            </a>
            <a href="leadgen" className="hidden md:block cursor-pointer text-white hover:text-ninjamd uppercase">
              Lead Generation
            </a>
            <a href="webdev" className="hidden md:block cursor-pointer text-white hover:text-ninjamd uppercase">
              Web Development
            </a>
            <a href="talent" className="hidden md:block cursor-pointer text-white hover:text-ninjamd uppercase">
              HR Outsourcing
            </a>
            <a href="seo" className="hidden md:block cursor-pointer text-white hover:text-ninjamd uppercase">
              SEO
            </a>
            {/* <a href="copywriting" className="hidden md:block cursor-pointer text-white hover:text-ninjamd uppercase">
              Copywriting
            </a> */}
            <a href="contactform" className="hidden md:block cursor-pointer text-white hover:text-ninjamd uppercase">
              Contact
            </a>
            {/* <div className="flex flex-row space-x-8 items-center justify-between"> */}
              {/* <a href='https://www.linkedin.com/in/marketing-ninja-a8b821282'>
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                    fill="white"
                  ></path>
                </svg>
              </a> */}
              {/* <a>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                    fill="white"
                  />
                </svg>
              </a> */}
              <a href=""></a>
            {/* </div> */}
          </div>
          <hr className="border-gray-600 hidden md:block" />
          <p className="w-full text-center lg:mt-12 md:my-12 text-white p-12 md:p-0">
            Copyright © 2024 Axis Alpha Consulting
          </p>
        </div>
      </div>
    </div>
  );
}


import Banner from "../components/Banner";
import Faq from "../components/Faq";
import Form from "../components/Form";
import Pricing from "../components/Pricing";
import Cards from "../components/Cards";
import Zigzag from "../components/Zigzag";
import RocketIcon from "../assets/rocket.svg";
import Content from "../assets/content.webp"
import Competition from "../assets/competition.webp"
import Customer from "../assets/customers.webp"
import Seop from "../assets/seo.webp"
import Seog from "../assets/seog.webp"
import Imgb from "../assets/i4.png"
export default function Seo() {
  const imgb = Imgb
  const bannerHead ="Boost your Google rankings with affordable SEO starting at £199/month"
  const bannerText = ""
  const bannerButton ="Get Started"

  const cImg1 = Content
  const cHead1 = "Content-Centric Strategy"
  const cText1 = "At Axis Alpha Consulting, our SEO approach revolves around content-first strategies. We prioritize quality content, link building, and optimization techniques, avoiding any unusual or risky tactics. Our focus is on delivering sustainable and effective SEO results through trusted methods."
  const cImg2 = Competition
  const cHead2 = "Surpass rivals"
  const cText2 = "Outrank your competitors on Google, locally and nationally, through our affordable SEO campaigns. Gain the visibility and competitive edge you need to succeed online."
  const cImg3 = Customer
  const cHead3 = "Expand customer base"
  const cText3 = "More Google traffic = more customers buying and subscribing to your services."
  
  const zzi1 =Seop
  const zzh1 ="A wallet-friendly, rational approach to SEO 🌟"
  const zzt1 ="While marketing agencies may charge thousands of pounds for SEO campaigns, you don't have to break the bank to achieve results. Our packages start from just £199 per month, allowing you to compete with rival firms on Google and Bing regardless of your business size. Affordable SEO solutions that deliver effective outcomes are within your reach."
  const zzi2 =Seog
  const zzh2 ="Achieve higher rankings through an authentic and safe process 🌱"
  const zzt2 ="At Axis Alpha Consulting, we prioritize long-term success over quick wins. We are transparent about the fact that SEO requires time and is an investment worth making for businesses looking to decrease their reliance on paid ads. Our tactics are thoroughly tested on real websites, focusing on safe and natural methods to improve rankings. We strictly avoid black hat tactics or short-term strategies, ensuring a sustainable and ethical approach to SEO."
  
  return (
    <>
      <Banner image={imgb} bannerHead={bannerHead} bannerText={bannerText} bannerButton={bannerButton} />
      <Cards cImg1={cImg1} cHead1={cHead1} cText1={cText1} cImg2={cImg2} cHead2={cHead2} cText2={cText2} cImg3={cImg3} cHead3={cHead3} cText3={cText3} />
      <Zigzag zzi1={zzi1} zzh1={zzh1} zzt1={zzt1} zzi2={zzi2} zzh2={zzh2} zzt2={zzt2} />
      <Form />
    </>
  );
}
